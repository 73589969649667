import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ["select"];
  static values=  {
    condition: { type: String },
    selector: { type: String },
    autoHide: { type: Boolean, default: true }
  };

  connect() {
    this.selectElement = this.hasSelectTarget ? this.selectTarget : this.element
    if (this.autoHideValue) {
      this.showOrHide();
    }
  }

  // Je nach conditionValue wird das Element angezeigt oder versteckt
  showOrHide() {
    let targetElements = document.querySelectorAll(this.selectorValue);
    if (targetElements) {
      targetElements.forEach(element => {
        element.style.display = this.passesCondition ? "" : "none";
      });
    }
  };

  get passesCondition() {
    return this.selectElement.value === this.conditionValue;
  }
}
