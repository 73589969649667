import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dom-manipulations"
export default class extends Controller {
  static targets = ["element", "icon", "text", "destroyField"]
  static classes = ["icon1", "icon2"]

  removeElement(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.remove() )
  }

  showElement(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.classList.remove("d-none") )
  }

  hideElement(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.classList.add("d-none") )
  }

  setValue(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    const value = event.params.value || "";
    this.elementTargets.forEach(element => element.value = value)
  }

  toggleVisibility(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.classList.toggle("d-none") )
  }

  // Wechselt zwischen zwei Icon Klassen
  toggleIcon(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.iconTargets.forEach(element => {
      if (element.classList.contains(this.icon1Class)) {
        element.classList.remove(this.icon1Class);
        element.classList.add(this.icon2Class);
      } else {
        element.classList.remove(this.icon2Class);
        element.classList.add(this.icon1Class);
      }
    });
  }

  toggleCssClass( { params: {cssClass} } ) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;
    this.textTargets.forEach(element => element.classList.toggle(cssClass) )
  }

  setDestroyField() {
    this.destroyFieldTargets.forEach(element => element.value = true )
  }

  setOpacity(event, opacity = 0.5) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.style.opacity = opacity )
  }

  removeOpacity(event) {
    if (event.target.classList.contains("dom-manipulation-execption")) return;

    this.elementTargets.forEach(element => element.style.opacity = "" )
  }
}
